import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import WorkIcon from '@mui/icons-material/WorkOutline';
import SchoolIcon from '@mui/icons-material/SchoolOutlined';
import Typography from '@mui/material/Typography';
import Resume from '../../resume.json';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

const StyledTimelineItem = styled(TimelineItem)(({ theme }) => ({
    '&:before': {
        flex: 0,
        padding: 0,
    },
}));

const StyledTimelineContent = styled(TimelineContent)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'left',
}));

const TimelineEvent = ({ item, type }: any): any => (
    <>
        <StyledTimelineItem>
            <TimelineContent sx={{ paddingLeft: 2, paddingRight: 2 }}>
                <Typography variant="h6" component="span" sx={{ fontWeight: 'bold', fontFamily: '"Orbitron", serif;',  textAlign: 'center', display: 'block' }}>
                    {type === 'work' ? item.company : item.institution}
                </Typography>
            </TimelineContent>
        </StyledTimelineItem>
        <StyledTimelineItem>
            <StyledTimelineContent>
                <Typography variant="h6" component="span">
                    {type === 'work' ? item.position : item.studyType}
                </Typography>
                <Typography></Typography>
                <Typography variant="body2">
                    {new Date(item.startDate).toLocaleString('en-UK', { month: 'long', year: 'numeric' })} -
                    {item.endDate ? ` ${new Date(item.endDate).toLocaleString('en-UK', { month: 'long', year: 'numeric' })}` : ' Present'}
                </Typography>
                <Typography>{type === 'work' ? item.summary : item.area}</Typography>
            </StyledTimelineContent>
        </StyledTimelineItem>
    </>
);

const renderTimelineItems = (year: any, type: any): any => {
    const resumeData: any = Resume as any;  // Casting Resume to any
    const items: any = resumeData[type]
        .filter((event: any) => new Date(event.startDate).getFullYear() === year)
        .map((item: any, index: number) => {
            const uniqueKey = `${year}-${type}-${item.name}-${item.startDate}`;
            return (
                <motion.div
                    key={uniqueKey}
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 3.5, type: 'spring' }} // Stagger the delay for each item
                >
                    <TimelineEvent key={uniqueKey} item={item} type={type} />
                </motion.div>
            );
        });

    return items;
};

class CustomizedTimeline extends React.Component {
    render(): any {
        const yearsSet: any = new Set();
        Resume.work.forEach((item: any) => yearsSet.add(new Date(item.startDate).getFullYear()));
        Resume.education.forEach((item: any) => yearsSet.add(new Date(item.startDate).getFullYear()));
        const yearsList: any = Array.from(yearsSet).sort((a: any, b: any) => b - a);

        return (
            <Timeline position="left">
                {yearsList.map((year: any) => (
                    <React.Fragment key={year}>
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={{
                                hidden: { opacity: 0 },
                                visible: {
                                    opacity: 1,
                                    transition: {
                                        staggerChildren: 0.5, // Stagger the appearance of each item by 0.5 seconds
                                    },
                                },
                            }}
                        >
                            {renderTimelineItems(year, 'work')}
                            {renderTimelineItems(year, 'education')}
                        </motion.div>
                    </React.Fragment>
                ))}
            </Timeline>
        );
    }
}

export default CustomizedTimeline;
